/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { DdmServiceV2 } from '../../DdmServiceV2/DdmServiceV2';
import { ServiceAdapter } from './serviceAdapter';

export class AtdOffersAdapter extends ServiceAdapter {
  constructor(service) {
    super();
    this.service = service;
  }

  getToday() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  }

  async getOffers() {
    const offersV2 = new DdmServiceV2();
    const offers = await offersV2['getOffers']([
      { active: true },
      { expiration_gte: this.getToday() },
      { per_page: 100 },
    ]);
    return offers.entries.map(offer => {
      const {
        id,
        item_image,
        name,
        model,
        brand,
        seal_list,
        offer_type,
        item_offer,
        price,
        offer_category,
        slug,
        expired_at,
      } = offer;

      return {
        id,
        base_type: 'Autodromo',
        thumbs: item_image,
        name,
        model,
        brand,
        seal_list,
        offer_type,
        item_offer,
        price,
        category: offer_category,
        slug,
        expired_at,
      };
    });
  }
}
