/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import { ServiceAdapter } from './serviceAdapter';

export class DDMAdapter extends ServiceAdapter {
  constructor(service) {
    super();
    this.service = service;
  }

  async getOffers() {
    try {
      const response = await this.service.getOffers();
      const data = this._adaptOffersSchema(response.hits.hit);

      const baseType = data.length > 0 && data[0].base_type;
      if (baseType === 'Marketplace') return data;

      const filteredData = this._filterExceptionModels(data);
      return filteredData;
    } catch (error) {
      return [];
    }
  }

  async getOffer(id) {
    try {
      const response = await this.service.getOffer(id);
      const data = this._adaptOffersSchema(response.hits.hit);

      return data;
    } catch (error) {
      return [];
    }
  }

  _adaptOffersSchema(offers) {
    return offers.map(offer => {
      const {
        model_short_name,
        version_name,
        brand,
        highlight,
        price_type,
        category,
        disclaimer,
        mvs,
        model_year,
        end_date,
      } = offer.fields;

      const id = this._getId(offer);
      const base_type = this._getBaseType(offer);
      const thumbs = this._getOfferThumbs(offer);
      const item_offer = this._getOfferPrice(offer);
      const expired_at = end_date ? end_date.split('T')[0] : '';

      return {
        id,
        base_type,
        thumbs,
        item_offer,
        name: model_short_name,
        model: version_name,
        brand,
        seal_list: highlight,
        offer_type: price_type,
        price: item_offer.price,
        category,
        slug: id,
        disclaimer,
        product_code: mvs,
        model_year,
        expired_at,
      };
    });
  }

  _filterExceptionModels(data) {
    const exceptionModels =
      window.channelData && window.channelData.exceptionModels;

    if (!exceptionModels) return data;
    const lowerCasedExceptionModels = exceptionModels.map(exceptionModel =>
      exceptionModel.toLowerCase().trim(),
    );

    const filteredModels = data.filter(model => {
      const modelName = model.name.toLowerCase();

      return !lowerCasedExceptionModels.some(exceptionModelName =>
        modelName.includes(exceptionModelName),
      );
    });

    return filteredModels;
  }

  _getOfferThumbs(offer) {
    const {
      brand,
      model_code,
      version_code,
      series_code,
      model_year,
      color_code,
    } = offer.fields;

    const offerImage = `https://www.${brand}.com.br/content/dam/${brand}/products/${model_code.toLowerCase()}/${version_code.toLowerCase()}/${series_code.toLowerCase()}/${model_year}/page/profile-png/profile-${color_code}.png`;

    const thumbs = {
      middle_image: offerImage,
    };

    return thumbs;
  }

  _getOfferPrice(offer) {
    const { price_full, price_from, price_to, priceForEndUser, price_type } =
      offer.fields;

    const priceConfigurations = {
      Payment4: {
        price: price_full,
        main_call: 'À vista',
        featured: `${this._formatPrice(price_full)}`,
      },
      Payment5: {
        price: price_to,
        main_call: `De ${this._formatPrice(price_from)} por`,
        featured: `${this._formatPrice(price_to)}`,
      },
      default: {
        price: priceForEndUser,
        main_call: 'À vista',
        featured: `${this._formatPrice(priceForEndUser)}`,
      },
    };

    const item_offer =
      priceConfigurations[price_type] || priceConfigurations.default;

    return item_offer;
  }

  _getBaseType(offer) {
    const { national } = offer.fields;
    return national ? 'Hub' : 'Marketplace';
  }

  _getId(offer) {
    const { offer_id } = offer.fields;
    return Number(offer_id);
  }

  _formatPrice(value) {
    if (!value) return '';

    const formatPrice = Number(value);

    return formatPrice.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
